import React from 'react';
import SEO from '../components/seo';
import { Layout as AntLayout, Row, Col } from 'antd';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
import Jeremy from '../components/images/Jeremy';
import Kevin from '../components/images/Kevin';
import Desk from '../components/images/desk';

const { Content } = AntLayout;

const About = () => (
  <Layout className="about-layout">
    <SEO title="About IRC" />
    <Header theme="dark">
      <h1>We are IRC.</h1>
      <p>
        Helping companies like yours
        <br /> to reach their full potential.
      </p>
    </Header>
    <Content className="about-content">
      <Row gutter={32} type="flex">
        <Col xs={24} md={12} className="company-overview">
          <div>
            <div className="title-text">Company Overview</div>
            <p>
              Insurance Regulatory Consultants, LLC (IRC) is a full service
              state filing and regulatory compliance company which was formed in
              1997 by John Battles and Kevin Purcell. Our current staff has more
              than 100 years of insurance experience and it is that experience
              which has enabled us to develop expertise with all types of filing
              and product development projects. Since we started, our staff has
              produced more than 30,000 submissions.
            </p>
          </div>
        </Col>
        <Col xs={0} md={12}>
          <Desk />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col className="our-team">
          <div className="title-text">Our Team</div>
          <p>
            People who not only care about the end result, but the journey
            getting there. Our path to approvals connects you to experienced
            staff who are passionate, personable, and effective.
          </p>
        </Col>
        <Col>
          <Row className="faces" gutter={32}>
            <Col span={22} offset={1}>
              <div className="face">
                <Row gutter={32}>
                  <Col xs={24} sm={9}>
                    <Jeremy />
                    <div className="name">Jeremy W. Battles – Principal</div>
                  </Col>
                  <Col xs={24} sm={15}>
                    <p>
                      As Principal for IRC, Jeremy leads the firm’s talented
                      staff in producing results for its clients. Jeremy joined
                      IRC in 2001 and has run the NYC office with his partner,
                      Kevin Purcell, since 2003. Jeremy is a Chartered Property
                      &amp; Casualty Underwriter (CPCU) and has vast experience
                      in both P&amp;C and A&amp;H lines of insurance, with a
                      special focus on the more complex liability claims-made
                      products. Jeremy serves as the main liaison ensuring
                      quality and efficiency for IRC's diverse portfolio of
                      clients. Currently, Jeremy is leading IRC's advancements
                      in development of innovative software solutions. Jeremy
                      graduated from the University of Richmond, finished in the
                      top 100 in both the New York City and Boston Marathons,
                      and has a passion for global travel. He currently lives in
                      Jersey City and can often be found running in Liberty
                      State Park or enjoying dinner with his wife and children
                      at their local taqueria.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={22} offset={1}>
              <div className="face">
                <Row gutter={32}>
                  <Col xs={24} sm={9}>
                    <Kevin />
                    <div className="name">Kevin Purcell - Principal</div>
                  </Col>
                  <Col xs={24} sm={15}>
                    <p>
                      Kevin Purcell, CPCU, co-founded IRC with John Battles in
                      1997. Kevin began his insurance industry career in 1983 at
                      Insurance Services Office (ISO) where he worked for 6
                      years in both the Commercial Property and Personal Lines
                      Actuarial Departments. He completed three actuarial exams
                      while at ISO. Kevin moved on to The Home Insurance Company
                      beginning in 1989 in The Home's Government Relations
                      Department, working closely with the Actuarial, Accounting
                      and Systems areas on regulatory and state filing issues,
                      including the coordination of market conduct exams. Kevin
                      gained underwriting experience, managed Residual Markets
                      and helped administer Workers Compensation in The Home's
                      Product Management Department. Prior to the formation of
                      IRC, Kevin was the Manager of Government Relations for
                      Risk Enterprise Management (REM), overseeing the business
                      of The Home Insurance Company, which had been placed into
                      "run-off". REM Filing Services was created, for which he
                      performed financial, administrative, and regulatory
                      functions. Kevin graduated from Duke University where he
                      earned a BS in Mathematics and continues to be a big Duke
                      basketball fan. Kevin and his family enjoy sight-seeing,
                      mostly visiting cities throughout the U.S.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={22} offset={1}>
              <div className="face">
                <Row>
                  <Col span={24}>
                    <div className="name">
                      <p>Kelly Gunning - Project Manager</p>
                    </div>
                    <p>
                      Kelly joined IRC in August of 2008. Kelly is a Chartered
                      Property &amp; Casualty Underwriter (CPCU) and the lead
                      contact for several IRC clients and projects. Kelly has
                      experience across all P&amp;C lines of insurance, with a
                      special focus on Workers Compensation. Client
                      relationships and attention to detail are at the forefront
                      of all projects. Kelly graduated from the University of
                      Florida with degrees in Communications and Management.
                      Kelly grew up an “Army Brat” and still struggles with the
                      question, “Where are you from?” After nearly 10 years in
                      New York City, she is currently trying out Minnesota. She
                      lives in St. Paul, MN with her husband and young daughter.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={22} offset={1}>
              <div className="face">
                <Row>
                  <Col span={24}>
                    <div className="name">
                      <p>Fong Li - Product Manager</p>
                    </div>
                    <p>
                      Fong joined IRC January of 2010. Fong graduated from Pace
                      University majoring in Finance and is a Chartered Property
                      &amp; Casualty Underwriter (CPCU). He has gained
                      experience with all Property &amp; Casualty lines of
                      insurance including experience with niche products like
                      Trade Credit Insurance. Fong also has worked in building
                      Excel rating engines for ISO based commercial and personal
                      products. Fong enjoys spending some of his time rooting
                      for his favorite blue and orange baseball team.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={22} offset={1}>
              <div className="face">
                <Row>
                  <Col span={24}>
                    <div className="name">
                      <p>Jason Graciolett – Senior Analyst</p>
                    </div>

                    <p>
                      Jason has a combined 18 years of HR administration and
                      insurance experience. He has been with IRC since June
                      2006. He is a graduate of Montclair State University and
                      holds the Chartered Property &amp; Casualty Underwriter
                      (CPCU) designation. Jason has had the opportunity to work
                      on all lines within the P&amp;C space and has particular
                      experience with Aviation and SFAA-related products. His
                      work is meticulous and always maintains a sharp focus on
                      client service. Jason and his wife enjoy music, travel,
                      and staying fit by chasing after their rambunctious son.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
    <Footer />
  </Layout>
);

export default About;
